<template>
  <div class="container-fluid">
    <b-alert show variant="success"> Срок действия ваших сертификатов привязан к сроку действия сертификата производителя </b-alert>
    <b-alert show variant="danger" v-if="anotherManufacturerRequest">
      Все Ваши сертификаты могут быть только от одного производителя. Принимая запрос от другого производителя, Вы даете согласие на аннулирование Ваших текущих сертификатов.
    </b-alert>
    <TableWithPagination
      ref="partnerOffices"
      :fields="officesFields"
      :items="partnerOffices"
      :totalRows="partnerOfficesTotal"
      @change-params="onOfficesChangeParams"
      @row-selected="onOfficesRowSelected"
    >
      <template v-slot:filters-actions>
        <b-form-checkbox v-model="switchEditMode" name="check-button" switch>
          <b v-if="!switchEditMode" class="pl-4 switch-label">Редактировать</b>
          <b v-if="switchEditMode" class="pl-4 switch-label">Вернуться к просмотру</b>
        </b-form-checkbox>
      </template>
    </TableWithPagination>

    <RightSidebar
      form="OfficeForm"
      :form-data="officeFormData"
      :mode="officeFormMode"
      :show="officeFormShow"
      @update:show="officeFormShow = $event;officeFormData = {};"
      @saved:form-data="onSavedOfficeForm"
    >
    </RightSidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import { DateFormat } from "@/helpers";
import { TableWithPagination, RightSidebar } from "@/components";

export default {
  name: "PartnerOffices",
  components: { TableWithPagination, RightSidebar },
  data() {
    return {
      switchEditMode: false,
      
      officesFields: [
        { key: "spotName", label: "Наименование офиса" },
        { key: "spotAddress", label: "Фактический адрес" },
        { key: "spotAddressPhone", label: "Телефон" },
        { key: "spotAccost", label: "Менеджер офиса продаж" },
        { key: "hasCertificate", label: "Сертификат", formatter: (item) => (item ? "Да" : "Нет") },
        { key: "certificateExpirationDate", label: "Действителен до", formatter: (item) => DateFormat.getDate(item) },
      ],
      selectedOffice: null,
      officeFormData: {},
      officeFormMode: "EDIT",
      officeFormShow: false,
    };
  },
  methods: {
    ...mapActions("partners", ["fetchPartnerOffices", "dealerVerify", "fetchPartner", "checkForAnotherManufacturerRequest", "changeOffice"]),

    onOfficesChangeParams(params) {
      this.fetchPartnerOffices({ id: this.partnerId, params });
    },
    onOfficesRowSelected(office) {
      this.selectedOffice = office;
      if (office && this.switchEditMode) this.onEditOffice()
      else if (office) this.$router.push(`/offices/${office.id}/certificates`);
    },

    onEditOffice() {
      this.officeFormData = { ...this.selectedOffice };
      this.officeFormMode = "EDIT"
      this.officeFormShow = true
    },

    onSavedOfficeForm() {
      this.$refs.partnerOffices.reload();
    }, 
  },
  computed: {
    ...mapState({ partnerId: (state) => state.auth.user.partnerId }),
    ...mapState("partners", {
      partner: (state) => state.partner,
      partnerOffices: (state) => state.partnerOffices,
      partnerOfficesTotal: (state) => state.partnerOfficesTotal,
      isDealerConfirmed: (state) => state.confirmed,
      dealerVerifyDetailes: (state) => state.dealerVerifyDetailes,
      anotherManufacturerRequest: (state) => state.anotherManufacturerRequest,
    }),
  },

  created() {
    if (this.partnerId) {
      this.dealerVerify().then(() => {
        if (!this.isDealerConfirmed) this.$router.push("/partner/verify");
        else
          this.fetchPartner(this.partnerId).then(() => {
            this.checkForAnotherManufacturerRequest();
          });
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
